<template>
  <div>
    <span> caller's ID: </span>
    <input v-model="callerUserID" type="text">
    <button @click="init"> step1. init</button>
    <br>
    <span> callee's ID: </span>
    <input v-model="calleeUserID" type="text">
    <button @click="call"> step2. call</button>

    <!--【1】Import the TUICallKit component: Call interface UI -->
    <TUICallKit style="width: 650px; height: 500px "/>
  </div>
</template>

<script>
import {TUICallKit, TUICallKitServer, TUICallType} from "@tencentcloud/call-uikit-vue2";
import * as GenerateTestUserSig from "./debug/GenerateTestUserSig-es";

export default {
  name: 'App',
  data() {
    return {
      callerUserID: '',
      calleeUserID: '',
      SDKAppID: 1400720608,      // TODO: Replace with your SDKAppID
      SecretKey: '57decd5b7a808513faf51295fe8d370dbd3e2b425094a5564bedc85c049700f6',  // TODO: Replace with your SecretKey
    };
  },
  components: {
    TUICallKit
  },
  methods: {
    //【2】Initialize the TUICallKit component
    async init() {
      try {
        const {userSig} = GenerateTestUserSig.genTestUserSig({
          userID: this.callerUserID,
          SDKAppID: Number(this.SDKAppID),
          SecretKey: this.SecretKey,
        });
        await TUICallKitServer.init({
          SDKAppID: Number(this.SDKAppID),
          userID: this.callerUserID,
          userSig,
          // tim: this.tim     // 如果工程中已有 tim 实例，需在此处传入
        });
        alert("[TUICallKit] Initialization succeeds.");
      } catch (error) {
        alert(`[TUICallKit] Initialization failed. Reason: ${error}`);
      }
    },
    //【3】Make a 1v1 video call
    async call() {
      await TUICallKitServer.call({
        userID: this.calleeUserID,
        type: TUICallType.VIDEO_CALL,
      });
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
